import React, {useEffect} from 'react';
import { Paper, Typography, CircularProgress, Divider, Tooltip, Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import {ExpandMore} from '@material-ui/icons'
import {useDispatch, useSelector} from 'react-redux'
import moment from 'moment';
import {useParams, useHistory} from 'react-router-dom';

import {getRequest, deleteRequest, completeRequest} from '../../../../actions/requests';
import useStyles from './styles';

export default function RequestDetails(){
  const { request, requests, isLoading } = useSelector((state)=>state.requests);
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const {id} = useParams();

  useEffect(()=>{
    dispatch(getRequest(id));
      // eslint-disable-next-line
  }, [dispatch] );

  console.log(request);
  if(!request) return null;

  if(isLoading){
    return (<Paper elevation={6} className={classes.loadingPaper}>
        <CircularProgress size='7em'/>
    </Paper>)
  }

  const handleDeleteRequest = () =>{
    dispatch(deleteRequest(request._id));
    history.push("/requests");
  }

  const handleCompleteRequest = () =>{
    dispatch(completeRequest(request._id));
    history.push("/requests");
  }

  const download = e => {
    const link = document.createElement("a");
    link.href = 'data:application/octet-stream;base64 '+ e.target.src;
    link.setAttribute("download", "image.png"); //or any other extension
    document.body.appendChild(link);
    link.click();
    setTimeout( function () { link.parentNode.removeChild( link ); },10 );
  };

  let fileCount = 0;

  return (
  <Paper style={{padding: '20px', borderRadius: '15px'}} elevation={6}>
    <div className={classes.card}>
        <div className={classes.section}>
        {request.complete ? <Typography variant="h4" color="primary" component="h2" p={6}>Completo em: {new Date(Date.parse(request.completeAt)).toLocaleDateString()}</Typography> : <Typography variant="h5" component="h4" p={6}>{request.type}</Typography>}
        <Grid container
            direction="row"
            spacing={9}>
            <Grid item>
          <Typography variant="h3" component="h2" p={6}>{request.title}</Typography>
          </Grid>
          <Grid item>
          <Button variant="contained" color="primary" onClick={() => handleCompleteRequest(request._id)}>
            Completar
          </Button>
          </Grid>
          <Grid item>
          <Button variant="contained" color="secondary" onClick={() => handleDeleteRequest(request._id)}>
            Delete
          </Button>
          </Grid>
          </Grid>
          <Typography gutterBottom variant="body1" component="p">{request.message}</Typography>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>Visualize as imagens aqui:</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className={classes.imageSection}>
            {request.selectedFile.map((file)=>{
              const img = new Image();
              img.src = file;
            return (
              <div key={fileCount++} id="row">
              <Tooltip title={`${img.height}x${img.width} download.`} placement="right">
                <img onClick={(e)=>download(e)} className={classes.media} src={file || 'https://user-images.githubusercontent.com/194400/49531010-48dad180-f8b1-11e8-8d89-1e61320e1d82.png'} alt={request.title} />
              </Tooltip></div>)
            })}
          </div>
        </AccordionDetails>
      </Accordion>

          <Typography style={{paddingTop: 20}} variant="h6">Criado por: {request.name}</Typography>
          <Typography variant="body1">{moment(request.createAt).fromNow()}</Typography>
          <Divider style={{ margin: '20px 0' }} />
        </div>

      </div>
  </Paper>
  )
}
