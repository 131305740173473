import React, {useEffect} from 'react';
import { Paper, Typography, CircularProgress, Divider, Tooltip, Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import {ExpandMore} from '@material-ui/icons'
import {useDispatch, useSelector} from 'react-redux'
import moment from 'moment';
import {useParams, useHistory} from 'react-router-dom';
import {stateToHTML} from 'draft-js-export-html';
import {convertFromRaw} from 'draft-js';

import {getPost, getPostsBySearch } from '../../../actions/posts';
import useStyles from './styles';

const PostDetails = () =>{
  const { post, posts, isLoading } = useSelector((state)=>state.posts);
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const {id } = useParams();

  useEffect(()=>{
    dispatch(getPost(id));
      // eslint-disable-next-line
  }, [id] );
  let recommendedPosts = [];

  useEffect(()=>{
    if(post){
      dispatch(getPostsBySearch({tags: post?.tags.join(',')}));
    }
      // eslint-disable-next-line
  }, [post]);

  if(!post) return null;
  if(posts) recommendedPosts = posts.filter(({_id})=> _id !== post._id);

  if(isLoading){
    return (<Paper elevation={6} className={classes.loadingPaper}>
        <CircularProgress size='7em'/>
    </Paper>)
  }

  const download = e => {
    const link = document.createElement("a");
    link.href = 'data:application/octet-stream;base64 '+ e.target.src;
    link.setAttribute("download", "image.png"); //or any other extension
    document.body.appendChild(link);
    link.click();
    setTimeout( function () { link.parentNode.removeChild( link ); },10 );
  };

  const openPost = (_id)=> history.push(`/posts/${_id}`);
  let fileCount = 0;
  var message = null;
  try{
  message = stateToHTML(convertFromRaw(JSON.parse(post.message)));
}catch(e){
  console.log("Post has no or invalid message");
}
  return (
  <Paper style={{padding: '20px', borderRadius: '15px'}} elevation={6}>
    <div className={classes.card}>
        <div className={classes.section}>
          <Typography variant="h3" component="h2">{post.title}</Typography>
          <Typography gutterBottom variant="h6" color="textSecondary" component="h2">{post.tags.map((tag) => `#${tag} `)}</Typography>
          {//<Typography gutterBottom variant="body1" component="p">{post.message}</Typography>
          }
          {message && <div className={classes.message} dangerouslySetInnerHTML={{__html: message}}/>}

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>Aperte para baixar as Imagens:</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className={classes.imageSection}>
            {post.selectedFile.map((file)=>{
              const img = new Image();
              img.src = file;
            return (
              <div key={fileCount++} id="row">
              <Tooltip title={`${img.height}x${img.width} download.`} placement="right">
                <img onClick={(e)=>download(e)} className={classes.media} src={file || 'https://user-images.githubusercontent.com/194400/49531010-48dad180-f8b1-11e8-8d89-1e61320e1d82.png'} alt={post.title} />
              </Tooltip></div>)
            })}
          </div>
        </AccordionDetails>
      </Accordion>

          <Typography style={{paddingTop: 20}} variant="h6">Criado por: {post.name}</Typography>
          <Typography variant="body1">{moment(post.createAt).fromNow()}</Typography>
          <Divider style={{ margin: '20px 0' }} />
          {/*<Typography variant="body1"><strong>Realtime Chat - coming soon!</strong></Typography>
          <Divider style={{ margin: '20px 0' }} />
          <Typography variant="body1"><strong>Comments - coming soon!</strong></Typography>
          <Divider style={{ margin: '20px 0' }} />*/}
        </div>

      </div>
      {recommendedPosts.length ? (
        <div>
          <Typography gutterBottom variant="h5">Você pode também gostar de:</Typography>
          <Divider/>
          <div className={classes.recommendedPosts}>
            {recommendedPosts.map(({title, name, likes, selectedFile,  _id}) => (
              <Paper style={{padding: '10px', margin: '20px', cursor: 'pointer', border: '1px solid', borderRadius:"10px"}} onClick={()=> openPost(_id)} key={_id}>
                <Typography gutterBottom variant="h6">{title}</Typography>
                <Typography gutterBottom variant="subtitle1">Likes: {likes.length}</Typography>
                <img src={selectedFile[0]} alt={title} width="200px"/>
              </Paper>
            ))}
          </div>
        </div>
      ) : null}
  </Paper>
  )
}

export default PostDetails;
