import React, { useState } from 'react';
import { Avatar, Paper, Grid, Typography, Container, Button } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import { useDispatch } from 'react-redux'
import {useHistory} from 'react-router-dom'

import useStyles from './styles';
import Input from "./Input";
import {signin, signup, isAdmin } from '../../actions/auth';

const inititalFormState = { name: "", codigo: "", email: '', password: '', confirmPassword: ''};

const Auth = () => {
    const classes = useStyles();
    const [showPassword, setShowPassword] = useState(false);
    const [formData, setFormData] = useState(inititalFormState);
    const dispatch = useDispatch();
    const history = useHistory();
    const [isSignup, changeIsSignup] = useState( isAdmin() ? true : false);
    const handleShowPassword = ()=> setShowPassword((prevShowPassword) => !prevShowPassword);

    const handleSubmit = (e)=>{
      e.preventDefault();
      if(isSignup){
        dispatch(signup(formData, history));
      }else{
        dispatch(signin(formData, history));
      }
    };

    const handleChange = (e)=>{
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    // const googleSuccess = async (res) => {
    //   const result = res?.profileObj;
    //   const token = res?.tokenId;
    //
    //   try{
    //       dispatch({type: 'AUTH', data: {result, token}});
    //
    //       history.push('/');
    //   }catch(e){
    //     console.log(e);
    //   }
    // }
    // const googleFailure = (e) =>{
    //   alert("google sign in is a failure")
    //   console.log(e)
    // }

    const switchMode = () => {
      changeIsSignup((currentStatus) => !currentStatus);
      setShowPassword(false);
    }

    return (
        <Container component="main" maxWidth="xs">
            <Paper className={classes.paper} elevation={3}>
                <Avatar className={classes.avatar}>
                  <LockOutlinedIcon/>
                </Avatar>
                <Typography variant="h5">{isSignup ?  'Registrar' : 'Entrar'}</Typography>
                <form className={classes.form} onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                      {isSignup && (
                          <>
                              <Input name="name" label="Nome Completo" handleChange={handleChange} autoFocus/>
                              <Input name="codigo" label="Codigo de Franquia" handleChange={handleChange} type="text" />
                          </>
                          )}
                          <Input name="email" label="Endereço de Email" handleChange={handleChange} type="email" />
                          <Input name='password' label='Senha' handleChange={handleChange} type={showPassword ? 'text' : 'password'} handleShowPassword={handleShowPassword}  />
                          { isSignup && (<Input name="confirmPassword" label="Repita a Senha" handleChange={handleChange} type="password" />)}
                  </Grid>

                    <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                      {isSignup ? 'Registrar' : 'Entrar'}
                    </Button>
                    {/* <Grid container justify="flex-end">
                      <Grid item>
                          <Button onClick={switchMode}>
                            {isSignup ? "Já tem uma conta? Entre" :  "Não tem uma conta? Registre-se"}
                          </Button>
                      </Grid>
                    </Grid> */}
                </form>
            </Paper>
        </Container>
    )
}

export default Auth;
