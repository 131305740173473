import React, {useState, useEffect, useRef} from "react"
import { TextField, Button, Typography,  CircularProgress, Paper } from "@material-ui/core";
import MUIRichTextEditor from 'mui-rte';
import FileBase from 'react-file-base64';
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams } from 'react-router-dom';
import { convertToRaw, EditorState } from "draft-js"
import ChipInput from 'material-ui-chip-input';

import useStyles from "./styles"
import { getPost, createPost, updatePost } from "../../../actions/posts";

const Form = ()=>{
    const {post, isLoading } = useSelector((state)=>state.posts);
    const classes = useStyles();
    const user = JSON.parse(localStorage.getItem('profile'));
    const history = useHistory();
    let {id } = useParams();
    const rte = useRef(null)

    const [postData, setPostData] = useState({
        title: '',
        message: JSON.stringify(convertToRaw(EditorState.createEmpty().getCurrentContent())),
        tags: [],
        selectedFile: []
    });
    const dispatch = useDispatch();


        useEffect(()=>{
          if(id)dispatch(getPost(id));
            // eslint-disable-next-line
        }, [id] );

    useEffect(()=>{
            if(post && id) setPostData(post);
    }, [post, id])

    const handleSubmit = (e)=>{
        e.preventDefault();
        //Check if you're editing the post or creating a new one;
        try{
          // console.log(postData.message);//stateToHTML(convertFromRaw(JSON.parse(postData.message)))
        if(id) dispatch(updatePost(id,{...postData, name: user?.result?.name }, history));
        else dispatch(createPost({...postData, name: user?.result?.name }, history));
      }catch(e){
        console.log(e)
        alert(e.message);
      }
        // clear();
    };

    if(!user?.result?.name){
      return (
        <Paper className={classes.paper} elevation={6}>
          <Typography variant="h6" align="center">
            Por favor, entre na sua conta para enviar materiais.
          </Typography>
        </Paper>
      );
    }

    const clear = ()=>{
      id = null;
        setPostData({
            title: '',
            message: JSON.stringify(convertToRaw(EditorState.createEmpty().getCurrentContent())),
            tags: [],
            selectedFile: []
        });
        console.log(postData);
    }
    if(isLoading && id){
      return (<Paper elevation={6} className={classes.loadingPaper}>
          <CircularProgress size='7em'/>
      </Paper>)
    }

    const handleTagAdd = (tag)=> setPostData({...postData, tags: [...postData.tags, tag]});

    const handleTagDelete =  (tagToDelete)=> setPostData({...postData, tags: postData.tags.filter(tag=>tag!==tagToDelete)});

    return (
        <Paper className={classes.paper}>
            <form autoComplete="off" noValidate className={`${classes.root} ${classes.form}`} lg={12} onSubmit={(e)=>{e.preventDefault();}}>
                <Typography variant="h6">{id ? "Editando" : "Criando"} um Material</Typography>
                <TextField name="title" variant="outlined" label="Título" fullWidth value={postData.title} onChange={(e) => setPostData( { ...postData, title: e.target.value })}/>
                {/*<TextField name="message" variant="outlined" label="Message" fullWidth value={postData.message} onChange={(e) => setPostData( { ...postData, message: e.target.value })}/>*/}
                <Paper variant="outlined" className={classes.message}>
                    <MUIRichTextEditor  label="Começe a digitar..." ref={rte} defaultValue={postData.message} controls={["title", "bold", "italic", "underline", "strikethrough", "link", "media", "numberList", "bulletList", "quote", "clear", "save"]} onBlur={()=>rte.current.save()} onSave={(e) => setPostData({...postData, message: e})}/>
                </Paper>
              {/*  <TextField name="tags" variant="outlined" label="Tags" fullWidth value={postData.tags} onChange={(e) => setPostData( { ...postData, tags: e.target.value.replace(' ',',').split(',') })}/>*/}
                <ChipInput variant="outlined" fullWidth style={{margin: '10px 0 30px 10px'}} value={postData.tags} onAdd={handleTagAdd} onDelete={handleTagDelete} label="Tags" helperText="Pressione Enter para inserir Tags"/>
                <div className={classes.fileInput}><FileBase type="file" multiple={true} onDone={(files)=>setPostData({ ...postData, selectedFile: files.map((file)=>file.base64)})}/></div>
                <Button style={{width: 100}} variant="contained" color="primary" size="large" onClick={handleSubmit} fullWidth>Enviar</Button>
                <Button style={{width: 100}} variant="contained" color="secondary" size="small" onClick={clear} fullWidth>Limpar</Button>
            </form>
        </Paper>
    );
}

export default Form;
