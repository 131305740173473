import * as api from '../api'

//Actions Creators
import { AUTH } from "../constants/actionTypes";

export const signin = (formData, history) => async (dispatch) => {
  try{
    const { data } = await api.signIn(formData);
    dispatch({type: AUTH, data: data});

    history.push('/');
  }catch(e){
    alert("Ocorreu um erro no Sign-in.")
    console.log(e);
  }
};

export const signup = (formData, history) => async (dispatch) => {
  try{
    const { data } = await api.signUp(formData);

    //dispatch({type: AUTH, data: data});

    history.push('/');
  }catch(e){
    alert("Ocorreu um erro no Sign-up.")
    console.log(e);
  }
};

export const isAdmin = () => {
  try{
    const user = () => JSON.parse(localStorage.getItem('profile'));
    if(user()?.result.role === "admin") return true;
  }
  catch(e){
    console.log(e);
  }
  return false;
}
