import React, {useState} from "react"
import InputLabel from '@material-ui/core/InputLabel';
import { TextField, Button, Typography, Paper } from "@material-ui/core";
import FileBase from 'react-file-base64';
import {useDispatch} from "react-redux";
import {useHistory} from 'react-router-dom';
import NativeSelect from '@material-ui/core/NativeSelect';
import Grid from '@material-ui/core/Grid';

import useStyles from "./styles"
import {createRequest } from "../../../../actions/requests";

const Request = ()=>{
    const classes = useStyles();
    const user = JSON.parse(localStorage.getItem('profile'));
    const history = useHistory();

    const [postData, setPostData] = useState({
        title: '',
        message: "",
        type: "Alteração",
        selectedFile: []
    });
    const dispatch = useDispatch();

    const handleSubmit = (e)=>{
        e.preventDefault();
        try{
          dispatch(createRequest({...postData, name: user?.result?.name }, history));
      }catch(e){
        console.log(e)
        alert(e.message);
      }
        // clear();
    };

    if(!user?.result?.name){
      return (
        <Paper className={classes.paper} elevation={6}>
          <Typography variant="h6" align="center">
            Por favor entre na sua conta para fazer solicitações.
          </Typography>
        </Paper>
      );
    }

    const clear = ()=>{
        setPostData({
            title: '',
            message: "",
            selectedFile: []
        });
        console.log(postData);
    }

    return (
        <Paper className={classes.paper}>
            <form autoComplete="off" noValidate className={`${classes.root} ${classes.form}`} lg={12} onSubmit={(e)=>{e.preventDefault();}}>
                <Typography variant="h6">Solicitando um Material</Typography>
                <TextField name="title" variant="outlined" label="Título" fullWidth value={postData.title} onChange={(e) => setPostData( { ...postData, title: e.target.value })}/>
                <TextField name="message" variant="outlined" label="Descrição" fullWidth value={postData.message} onChange={(e) => setPostData( { ...postData, message: e.target.value })}/>
                <Grid direction="row" container spacing={6} justify="center">
                <Grid item>
                <InputLabel id="demo-simple-select-helper-label">Tipo</InputLabel>
                <NativeSelect
          value={postData.type}
          onChange={(e) => setPostData( { ...postData, type: e.target.value })}
          inputProps={{
            name: 'type',
            id: 'type-native-helper',
          }}
        >
          <option value={"Alteração"}>Alteração em Material</option>
          <option value={"Apresentações"}>Apresentações</option>
          <option value={"Banner"}>Banner</option>
          <option value={"Campanhas"}>Campanhas</option>
          <option value={"Cartaz"}>Cartaz</option>
          <option value={"Comunicados"}>Comunicados</option>
          <option value={"Convite"}>Convite</option>
        </NativeSelect>
        </Grid>
        <Grid item>
                <div className={classes.fileInput}><FileBase type="file" multiple={true} onDone={(files)=>setPostData({ ...postData, selectedFile: files.map((file)=>file.base64)})}/></div>
                </Grid>
                </Grid>
                <Button style={{width: 100}} variant="contained" color="primary" size="large" onClick={handleSubmit} fullWidth>Enviar</Button>
                <Button style={{width: 100}} variant="contained" color="secondary" size="small" onClick={clear} fullWidth>Limpar</Button>
            </form>
        </Paper>
    );
}

export default Request;
