import React from 'react';
import { Container } from "@material-ui/core";
import { MARKETING, MARKETING_POST , REQUESTS, REQUEST_POST} from "./constants/routes"

import { BrowserRouter, Switch, Route,  Redirect } from 'react-router-dom';

import NavBar from './components/NavBar/NavBar';
import Marketing from './components/Marketing/Marketing';
import Form from './components/Marketing/Form/Form'
import EnhancedTable from './components/Marketing/Requests/EnhancedTable/EnhancedTable'
import Request from './components/Marketing/Requests/Form/Request'
import Auth from './components/Auth/Auth';
import PostDetails from './components/Marketing/PostDetails/PostDetails';
import RequestDetails from './components/Marketing/Requests/Details/RequestDetails';
import {isAdmin} from './actions/auth'

const App = ()=> {
    const user = () => JSON.parse(localStorage.getItem('profile'));
    return (
        <BrowserRouter>
        <Container maxidth="xl">
            <NavBar/>
            <Switch>
                <Route path="/" exact component={ () => <Redirect to={`/${MARKETING}`} /> } />
                <Route path= {`/${MARKETING}`} exact component={Marketing}/>
                <Route path={`/${MARKETING}/search`} exact component={Marketing}/>
                <Route path={`/${MARKETING}/:id`} component={PostDetails}/>
                <Route path={`/${MARKETING_POST}`} exact component={Form}/>
                <Route path={`/${MARKETING_POST}/:id`} component={Form}/>
                <Route path={`/${REQUESTS}`} exact component={EnhancedTable}/>
                <Route path={`/${REQUEST_POST}`} exact component={Request}/>
                <Route path={`/request/:id`} component={RequestDetails}/>
                <Route path="/auth" exact component={()=> !user() || isAdmin() ? <Auth/> : <Redirect to={`/${MARKETING}`} /> } />
            </Switch>
        </Container>
        </BrowserRouter>
    );
}

export default App;
