import axios from 'axios';
// 'http://localhost:3001'
// 'https://materialagil.herokuapp.com/'
// ''https://server.maisagilfranquias.com/'
var API_URL = 'https://server.maisagilfranquias.com/'
if(process.env.NODE_ENV === "development") API_URL= 'http://localhost:3001'
const   API = axios.create({ baseURL: API_URL });

API.interceptors.request.use((req)=>{
  const profile = localStorage.getItem('profile');

  if(profile){
    req.headers.authorization = `Bearer ${JSON.parse(profile).token}`;
  }

  return req;
});

export const fetchPost = (id) => API.get(`/posts/${id}`);
export const fetchPosts = (page) => API.get(`/posts?page=${page}`);
export const fetchPostsBySearch = (searchQuery) => API.get(`/posts/search?searchQuery=${searchQuery.search || 'none'}&tags=${searchQuery.tags}`);
export const createPost = (newPost) => API.post('/posts', newPost);
export const updatePost = (id, updatedPost) => API.patch(`/posts/${id}`, updatedPost);
export const deletePost = (id) => API.delete(`/posts/${id}`);
export const likePost = (id) => API.patch(`/posts/${id}/likePost`);

export const signIn = (formData) => API.post(`/user/signin`, formData);
export const signUp = (formData) => API.post(`/user/signup`, formData);

export const fetchRequests = () => API.get(`/requests`);
export const fetchRequest = (id) => API.get(`/requests/${id}`);
export const createRequest = (request) => API.post('/requests', request);
export const deleteRequest = (id) => API.delete(`/requests/${id}`);
export const completeRequest = (id) => API.patch(`/requests/${id}/complete`);
