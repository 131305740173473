export const START_LOADING = 'START_LOADING';
export const END_LOADING = 'END_LOADING';

export const CREATE = 'CREATE';
export const UPDATE = 'UPDATE';
export const DELETE = 'DELETE';
export const FETCH_POST = 'FETCH_POST';
export const FETCH_ALL = 'FETCH_ALL';
export const FETCH_BY_SEARCH = 'FETCH_BY_SEARCH';

export const AUTH = 'AUTH';
export const LOGOUT = 'LOGOUT';

//Requests

export const FETCH_ALL_REQUESTS = "FETCH_ALL_REQUESTS"
export const START_LOADING_REQUEST = 'START_LOADING_REQUEST'
export const END_LOADING_REQUEST = "END_LOADING_REQUEST"
export const FETCH_REQUEST = 'FETCH_REQUEST'
export const DELETE_REQUEST = 'DELETE_REQUEST'
export const UPDATE_REQUEST = 'UPDATE_REQUEST'
