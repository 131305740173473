import React, {useState, useEffect } from "react";
import {useDispatch } from 'react-redux';
import decode from 'jwt-decode';
import { AppBar, Toolbar, Typography, Button, Avatar} from "@material-ui/core";

import { Link, useHistory, useLocation } from "react-router-dom"
import Sidebar from '../Sidebar/Sidebar'

import useStyles from "./styles";
import agilLogo from '../../images/logo_topo.png';
import {isAdmin} from '../../actions/auth'

const NavBar = () => {
  const classes = useStyles();
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const logout = () =>{
    dispatch({type: 'LOGOUT'});
    history.push("/")
    setUser(null);
  }

  useEffect(()=> {
    const token = user?.token;
    if(token ){
      const decodedToken = decode(token);
      if(decodedToken.exp * 1000 < new Date().getTime()) logout();
    }
    setUser(JSON.parse(localStorage.getItem('profile')));
    // eslint-disable-next-line
  }, [location]);

  return (
    <AppBar className={classes.appBar} position="static" color="inherit">
      <Sidebar/>
      <Link to='/' className={classes.brandContainer}>
        <Typography className={classes.fontFaceNP} variant='h3'>Material</Typography>
        <img
          className={classes.image}
          src={agilLogo}
          alt="memories"
          height="60"
        />
    </Link>
      <Toolbar className={classes.toolbar}>
        {user ? (
            <div className={classes.profile}>
                <Avatar className={classes.purple} alt={user.result.name} src={user.result.imageUrl}>{user.result.name.charAt(0)}</Avatar>
                <Typography className={classes.userName} variant="h6">{user.result.name}</Typography>
                <Button variant="outlined" className={classes.logout} color="secondary" onClick={logout}>Sair</Button>
                {isAdmin() && <Button component={Link} to="/auth" variant="outlined" color="primary">Cadastrar</Button>}
            </div>
        ) : (
            <Button component={Link} to="/auth" variant="outlined" color="primary">Entrar</Button>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
