import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import BrushIcon from '@material-ui/icons/Brush';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import {useHistory} from 'react-router-dom';
import {MARKETING, MARKETING_POST, REQUESTS, REQUEST_POST } from '../../constants/routes';
import {isAdmin} from '../../actions/auth'

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  fontFaceNP: {
    fontFamily: "NoirPro",
  }
});

export default function Sidebar() {
  const user = () => JSON.parse(localStorage.getItem('profile'));
  const history = useHistory();
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
    <List>
      <Typography variant="h5" className={classes.fontFaceNP} style={{textAlign: "center"}}>Marketing</Typography>
      <Divider/>
      <ListItem button onClick={()=>history.push(`/${MARKETING}`)}>
        <ListItemIcon><BrushIcon/></ListItemIcon>
        <ListItemText className={classes.fontFaceSt} primary="Materiais"/>
      </ListItem>
      {(isAdmin() &&<ListItem button onClick={()=>history.push(`/${MARKETING_POST}`)}>
        <ListItemIcon><AddPhotoAlternateIcon/></ListItemIcon>
        <ListItemText className={classes.fontFaceSt} primary='Postar Material'/>
      </ListItem>)}
      <Divider style={{marginTop: "50px"}}/>
      <Typography variant="h5" className={classes.fontFaceNP} style={{textAlign: "center", margin: '10px'}}>Solicitar Material</Typography>
      <Divider/>
        {(isAdmin() &&<ListItem button onClick={()=>history.push(`/${REQUESTS}`)}>
          <ListItemIcon><PhotoLibraryIcon/></ListItemIcon>
          <ListItemText className={classes.fontFaceSt} primary='Solicitações'/>
        </ListItem>)}
        {(user() &&<ListItem button onClick={()=>history.push(`/${REQUEST_POST}`)}>
          <ListItemIcon><AddPhotoAlternateIcon/></ListItemIcon>
          <ListItemText className={classes.fontFaceSt} primary='Solicitar Material'/>
        </ListItem>)}
    </List>
    </div>
  );

  return (
    <div>
        <React.Fragment key={'left'}>
        <IconButton edge="start" onClick={toggleDrawer('left', true)} color="inherit" aria-label="menu">
              <MenuIcon />
            </IconButton>
          <Drawer anchor={'left'} open={state['left']} onClose={toggleDrawer('left', false)}>
            {list('left')}
          </Drawer>
        </React.Fragment>
    </div>
  );
}
